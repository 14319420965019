import React from 'react';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';

import AboutPage from '../pages/about';
import AccessoriesPage from '../pages/accessories';
import InformationPage from '../pages/information';
import ProductsPage from '../pages/products';
import WelcomePage from '../pages/welcome';
import WhiskeyFurniturePage from '../pages/whiskey';
import WineFurniturePage from '../pages/wine';
import NotFoundPage from '../pages/not-found';
import Product from '../pages/product';
import { defaultWhiskeyChair, 
         defaultWhiskeyOttoman, 
         defaultWhiskeyStool, 
         defaultWhiskeyFirepit, 
         defaultWineChair, 
         defaultWineOttoman, 
         defaultWineStool,
         defaultHoopGlobe,
         defaultHoopLight,
         defaultHoopWineSign } from '../data';

import './App.css';

function App() {
  const history = useHistory();
  const location = useLocation();
  const prevLocation = React.useRef(location);
  const [ isToggleOpen, setToggleOpen ] = React.useState(false);
  
  function toggleCollapse() {
    setToggleOpen( !isToggleOpen );
  }

  return (
    <React.Fragment>
      <Switch location={location} >
        <Route exact path="/" >
          <WelcomePage isToggleOpen={isToggleOpen} toggle={toggleCollapse} />
        </Route>
        <Route path="/products" >
          <ProductsPage isToggleOpen={isToggleOpen} toggle={toggleCollapse} />
        </Route>
        <Route path="/about" >
          <AboutPage isToggleOpen={isToggleOpen} toggle={toggleCollapse} />
        </Route>
        <Route path="/information" > 
          <InformationPage isToggleOpen={isToggleOpen} toggle={toggleCollapse} />
        </Route>
        <Route exact path="/whiskey-barrel-furniture" >
          <WhiskeyFurniturePage isToggleOpen={isToggleOpen} toggle={toggleCollapse} />
        </Route>
        <Route path="/whiskey-barrel-furniture/adirondack-chair" >
          <Product product={defaultWhiskeyChair} isToggleOpen={isToggleOpen} toggle={toggleCollapse} />
        </Route>
        <Route path="/whiskey-barrel-furniture/adirondack-ottoman" >
          <Product product={defaultWhiskeyOttoman} isToggleOpen={isToggleOpen} toggle={toggleCollapse} />
        </Route>
        <Route path="/whiskey-barrel-furniture/stool" >
          <Product product={defaultWhiskeyStool} isToggleOpen={isToggleOpen} toggle={toggleCollapse} />
        </Route>
        <Route path="/whiskey-barrel-furniture/firepit" >
          <Product product={defaultWhiskeyFirepit} isToggleOpen={isToggleOpen} toggle={toggleCollapse} />
        </Route>
        <Route exact path="/wine-barrel-furniture" >
          <WineFurniturePage isToggleOpen={isToggleOpen} toggle={toggleCollapse} />
        </Route>
        <Route exact path="/wine-barrel-furniture/adirondack-chair" >
          <Product product={defaultWineChair} isToggleOpen={isToggleOpen} toggle={toggleCollapse} />
        </Route>
        <Route exact path="/wine-barrel-furniture/adirondack-ottoman" >
          <Product product={defaultWineOttoman} isToggleOpen={isToggleOpen} toggle={toggleCollapse} />
        </Route>
        <Route exact path="/wine-barrel-furniture/stool" >
          <Product product={defaultWineStool} isToggleOpen={isToggleOpen} toggle={toggleCollapse} />
        </Route>
        <Route exact path="/home-decor" >
          <AccessoriesPage isToggleOpen={isToggleOpen} toggle={toggleCollapse} />
        </Route>
        <Route exact path="/home-decor/light" >
          <Product product={defaultHoopLight} isToggleOpen={isToggleOpen} toggle={toggleCollapse} />
        </Route>
        <Route exact path="/home-decor/globe" >
          <Product product={defaultHoopGlobe} isToggleOpen={isToggleOpen} toggle={toggleCollapse} />
        </Route>
        <Route exact path="/home-decor/wine-sign" >
          <Product product={defaultHoopWineSign} isToggleOpen={isToggleOpen} toggle={toggleCollapse} />
        </Route>
        <Route path="*" >
          <NotFoundPage isToggleOpen={isToggleOpen} toggle={toggleCollapse} />
        </Route>
      </Switch>
    </React.Fragment>
  );
}

export default App;
