import React from 'react';
import { MDBContainer } from 'mdbreact';
import Layout from '../components/shared/Layout'


function AboutPage( { isToggleOpen, toggle } ) {

    return (
        <Layout isToggleOpen={isToggleOpen} toggle={toggle} minimalNavbar={true} title="About">
            <MDBContainer className="bg-4 p-5 text-center" fluid >
                <h3>About Us</h3>
                <p className="p-5">We started our journey by obtaining some barrels for personal use.  We found other people also wanted barrels, and products made from barrels.</p>
                <p className="p-5">And so ... we started making barrels products.</p>
            </MDBContainer>
        </Layout>
    );

}

export default AboutPage;