import React from 'react';
import Layout from '../components/shared/Layout'
import Products from '../components/products/Products';
import { defaultWhiskeyProducts } from '../data';

function WhiskeyFurniturePage( { isToggleOpen, toggle } ) {

    return (
        <Layout isToggleOpen={isToggleOpen} toggle={toggle} minimalNavbar={true} title="Whiskey" >
            <Products title="Whiskey Barrel Products" products={defaultWhiskeyProducts} />
        </Layout>
    );

}

export default WhiskeyFurniturePage;