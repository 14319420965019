import React from 'react';
import { MDBContainer, MDBJumbotron } from 'mdbreact';

function MainProductPage({ isToggleOpen, toggle }) {

    return (
        <MDBContainer className="home text-center" fluid >
            <MDBJumbotron className="bg-0 mb-0" >
                <h1>
                <span>
                    Welcome to KC Barrel Decor
                </span>
                </h1>
                <img src="assets/images/sailboat-icon-256.ico" className="mx-auto d-block" alt='Large Logo' />
                <h2>
                <span>
                    Handcrafted Barrel Furniture & Decor
                </span>
                </h2>
            </MDBJumbotron>
        </MDBContainer>
    );

}

export default MainProductPage;


