import React from 'react';
import Layout from '../components/shared/Layout'
import Products from '../components/products/Products';
import { defaultDecorProducts } from '../data';

function AccessoriesPage( { isToggleOpen, toggle } ) {

    return (
        <Layout isToggleOpen={isToggleOpen} toggle={toggle} minimalNavbar={true} title="Accessories" >
            <Products title="Home Decor and Accessories Products" products={defaultDecorProducts} />
        </Layout>
    );

}

export default AccessoriesPage;