import React from 'react';
import { useHistory } from 'react-router-dom';
import { useNProgress } from '@tanem/react-nprogress';

import { MDBCollapse, MDBIcon, MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavbarToggler, MDBNavItem, MDBNavLink } from 'mdbreact';

import logo from '../../images/sailboat-icon-128.ico';

import '../../app/App.css';

function Navbar( { isToggleOpen, toggle }) {
    const history = useHistory();
    const [isLoadingPage, setLoadingPage] = React.useState(true);
    const path = history.location.pathname;
    
    React.useEffect(() => {
        setLoadingPage(false);
    }, [path])

    return (
        <React.Fragment>
            <Progress isAnimating={isLoadingPage} />
            <MDBNavbar expand="lg" >
                <MDBNavbarBrand href="/" >
                    <img src={logo} className="mx-auto d-block" alt="Home Logo" />
                </MDBNavbarBrand>
                <MDBNavbarToggler onClick={toggle} >
                    <MDBIcon icon="bars" />
                </MDBNavbarToggler>
                <MDBCollapse id="navbarCollapse1" isOpen={isToggleOpen} navbar >
                    <MDBNavbarNav left >
                        <MDBNavItem >
                            <MDBNavLink to="/" onClick={toggle} >HOME</MDBNavLink>
                        </MDBNavItem>
                    </MDBNavbarNav>
                    <MDBNavbarNav right >
                    <MDBNavItem >
                            <MDBNavLink to="/products" onClick={toggle} >PRODUCTS</MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem >
                            <MDBNavLink to="/information" onClick={toggle} >INFORMATION</MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem >
                            <MDBNavLink to="/about" onClick={toggle} >ABOUT</MDBNavLink>
                        </MDBNavItem>
                    </MDBNavbarNav>
                </MDBCollapse>
            </MDBNavbar>
        </React.Fragment>
    );

}

function Progress({ isAnimating }) {
    const { animationDuration, isFinished, progress} = useNProgress({ isAnimating })
  
    return (
      <div 
        className="progressContainer"
        style={{
          opacity: isFinished ? 0 : 1,
          transition: `opacity ${animationDuration}ms linear`
        }}
      >
        <div 
          className="progressBar" 
          style={{
            marginLeft: `${(-1 + progress) * 100}%`,
            transition: `margin-left ${animationDuration}ms linear`
          }}
        >
          <div className="progressBackground" />
        </div>
      </div>
    )
}

export default Navbar;