import React from "react";
import Helmet from 'react-helmet';

function SEO({ title }) {
  const titleText = title ? `KC Barrel Decor • ${title}` : "KC Barrel Decor";

  return (
    <Helmet>
      <title>{titleText}</title>
    </Helmet>  
  )
}

export default SEO;
