import { MDBContainer } from 'mdbreact';
import React from 'react';
import ProductGrid from '../components/products/ProductGrid';
import Layout from '../components/shared/Layout';
import { defaultProducts } from '../data';

function WelcomePage( { isToggleOpen, toggle }) {

    return (
        <Layout isToggleOpen={isToggleOpen} toggle={toggle} >
            <MDBContainer className="bg-1 pt-5 pb-5" fluid >
                <ProductGrid products={defaultProducts} />
            </MDBContainer>
        </Layout>
    );

}

export default WelcomePage;