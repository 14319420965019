import { MDBContainer } from 'mdbreact';
import React from 'react';
import Layout from '../components/shared/Layout'


function NotFoundPage( { isToggleOpen, toggle } ) {

    return (
        <Layout isToggleOpen={isToggleOpen} toggle={toggle} minimalNavbar={true} title="Not Found" >
            <MDBContainer className="bg-1 p-5 text-center" fluid >
                <h3>Oops!</h3>
                <p>The page you are looking for couldn't be found.</p>
            </MDBContainer>
        </Layout>
    );

}

export default NotFoundPage;