import React from 'react';
import Layout from '../components/shared/Layout'
import { defaultProducts } from '../data';
import Products from '../components/products/Products';

function ProductsPage({ isToggleOpen, toggle }) {

    return (
        <Layout isToggleOpen={isToggleOpen} toggle={toggle} minimalNavbar={true} title="Products" >
            <Products title="Products" products={defaultProducts} />
        </Layout>
    );

}

export default ProductsPage;