import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';

function Footer() {

    return (
        <React.Fragment >
            <MDBContainer className="bg-5" fluid>
                <MDBRow>
                    <MDBCol sm="4" className="text-left">
                        <p className="pl-2 pt-4">
                            Barrel Workshop
                        </p>
                    </MDBCol>
                    <MDBCol sm="4" className="text-sm-center">
                        <p className="pl-2 pl-sm-0 pt-4">
                            913.401.9845
                        </p>
                    </MDBCol>
                    <MDBCol sm="4" className="text-sm-right">
                        <p className="pl-2 pl-sm-0 pr-2 pt-4">
                            Contact Us
                        </p>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </React.Fragment>
    );

}

export default Footer;