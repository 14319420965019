import { v4 as uuid } from 'uuid';

export const defaultProducts = [
    {
        id: uuid(),
        title: "Whiskey Barrel Furniture",
        description: "Furniture made from whiskey barrels",
        image: "../assets/images/whiskey-barrel-2.jpg",
        link: "/whiskey-barrel-furniture" 

    },
    {
        id: uuid(),
        title: "Wine Barrel Furniture",
        description: "Furniture made from wine barrels",
        image: "../assets/images/wine-barrel-1.jpg",
        link: "/wine-barrel-furniture"
    },
    {
        id: uuid(),
        title: "Home Decor & Accessories",
        description: "Home Decor and Accessories made from barrel products",
        image: "../assets/images/decor-products/hoop-light-6.jpg",
        link: "/home-decor"
    }
]

export const defaultWhiskeyProducts = [
    {
        id: uuid(),
        title: "Whiskey Adirondack Chair",
        description: "Handmade Adirondack Chair made from whiskey barrel staves",
        image: "../assets/images/whiskey-products/chair-1.jpg",
        link: "/whiskey-barrel-furniture/adirondack-chair"
      },
      {
        id: uuid(),
        title: "Whiskey Adirondack Ottoman",
        description: "Handmade Adirondack Ottoman made from whiskey barrel staves",
        image: "../assets/images/whiskey-products/ottoman-1.jpg",
        link: "/whiskey-barrel-furniture/adirondack-ottoman"
      },
      {
        id: uuid(),
        title: "Whiskey Bar Stool",
        description: "Handmade Bar Stool made from whiskey barrel staves",
        image: "../assets/images/whiskey-products/stool-6.jpg",
        link: "/whiskey-barrel-furniture/stool"
      },
      {
        id: uuid(),
        title: "Firepit",
        description: "Handmade Firepit constructed from a whiskey barrel",
        image: "../assets/images/whiskey-products/firepit-2.jpg",
        link: "/whiskey-barrel-furniture/firepit"
      }    
]

export const defaultWineProducts = [
    {
      id: uuid(),
      title: "Wine Adirondack Chair",
      description: "Handmade Adirondack Chair made from wine barrel staves",
      image: "../assets/images/wine-products/chair-1.jpg",
      link: "/wine-barrel-furniture/adirondack-chair"
    },
    {
      id: uuid(),
      title: "Wine Adirondack Ottoman",
      description: "Handmade Adirondack Ottoman made from wine barrel staves",
      image: "../assets/images/wine-products/ottoman-1.jpg",
      link: "/wine-barrel-furniture/adirondack-ottoman"
    },
    {
      id: uuid(),
      title: "Wine Bar Stool",
      description: "Handmade Bar Stool made from wine barrel staves",
      image: "../assets/images/wine-products/stool-6.jpg",
      link: "/wine-barrel-furniture/stool"
    }
]

export const defaultDecorProducts = [
{
    id: uuid(),
    title: "Hanging Light",
    description: "Handmade Hanging Light constructed from barrel hoops",
    image: "../assets/images/decor-products/hoop-light-6.jpg",
    link: "/home-decor/light"
},
{
    id: uuid(),
    title: "Yard Globe",
    description: "Handmade Yard Globe constructed from barrel hoops",
    image: "../assets/images/decor-products/globe.jpg",
    link: "/home-decor/globe"
},
{
    id: uuid(),
    title: "Wine Sign",
    description: "Wine Sign constructed from barrel hoops",
    image: "../assets/images/decor-products/wine-sign-1.jpg",
    link: "/home-decor/wine-sign"
}
]

export const defaultWhiskeyChair = {
    id: uuid(),
    title: "Whiskey Adirondack Chair",
    description: "Gorgeous adirondack chairs handmade from whiskey barrel staves.  The curves of this chair fit your body like a glove!",
    price: 350.00,
    images: [
        {
        id: uuid(),
        imageId: 1,
        image: "../assets/images/whiskey-products/chair-1.jpg",
        text: "Chair 1"
        },
        {
        id: uuid(),
        imageId: 2, 
        image: "../assets/images/whiskey-products/chair-2.jpg",
        text: "Chair 2"
        },
        {
        id: uuid(),
        imageId: 3,
        image: "../assets/images/whiskey-products/chair-3.jpg",
        text: "Chair 3"
        },
        {
        id: uuid(),
        imageId: 4,
        image: "../assets/images/whiskey-products/chair-4.jpg",
        text: "Chair 4"
        }
        
    ]
}

export const defaultWhiskeyOttoman = {
id: uuid(),
title: "Whiskey Adirondack Ottoman",
description: "Gorgeous adirondack Ottoman handmade from whiskey barrel staves.  A great companion for your adirondack chair!",
price: 125.00,
images: [
    {
        id: uuid(),
        imageId: 1,
        image: "../assets/images/whiskey-products/ottoman-1.jpg",
        text: "Ottoman 1"
    },
    {
        id: uuid(),
        imageId: 2, 
        image: "../assets/images/whiskey-products/ottoman-2.jpg",
        text: "Ottoman 2"
    },
    {
        id: uuid(),
        imageId: 3,
        image: "../assets/images/whiskey-products/ottoman-3.jpg",
        text: "Ottoman 3"
    },
    {
        id: uuid(),
        imageId: 4,
        image: "../assets/images/whiskey-products/ottoman-chair.jpg",
        text: "Ottoman 4"
    }
    
]
}  

export const defaultWhiskeyStool = {
    id: uuid(),
    title: "Whiskey Stool",
    description: "Gorgeous stool handmade from whiskey barrel staves.  A great stool for bars, decks, kitchens, and just anywhere a stool is needed!",
    price: 225.00,
    images: [
        {
            id: uuid(),
            imageId: 1,
            image: "../assets/images/whiskey-products/stool-1.jpg",
            text: "Stool 1"
        },
        {
            id: uuid(),
            imageId: 2, 
            image: "../assets/images/whiskey-products/stool-2.jpg",
            text: "Stool 2"
        },
        {
            id: uuid(),
            imageId: 3,
            image: "../assets/images/whiskey-products/stool-3.jpg",
            text: "Stool 3"
        },
        {
            id: uuid(),
            imageId: 4,
            image: "../assets/images/whiskey-products/stool-6.jpg",
            text: "Stool 4"
        },
        {
            id: uuid(),
            imageId: 5,
            image: "../assets/images/whiskey-products/stools.jpg",
            text: "Stool 5"
        }
    ]
}  
    
export const defaultWhiskeyFirepit = {
    id: uuid(),
    title: "Whiskey Barrel Firepit",
    description: "Functional firepit handmade from whiskey barrel staves.  An awesome propane fueled firepit to accent your patio and chairs!",
    price: 450.00,
    images: [
        {
            id: uuid(),
            imageId: 1,
            image: "../assets/images/whiskey-products/firepit-1.jpg",
            text: "Firepit 1"
        },
        {
            id: uuid(),
            imageId: 2, 
            image: "../assets/images/whiskey-products/firepit-2.jpg",
            text: "Firepit 2"
        },
        {
            id: uuid(),
            imageId: 3,
            image: "../assets/images/whiskey-products/firepit-3.jpg",
            text: "Firepit 3"
        },
        {
            id: uuid(),
            imageId: 4,
            image: "../assets/images/whiskey-products/firepit-4.jpg",
            text: "Firepit 4"
        },
        {
            id: uuid(),
            imageId: 5,
            image: "../assets/images/whiskey-products/firepit-5.jpg",
            text: "Firepit 5"
        },
        {
            id: uuid(),
            imageId: 6,
            image: "../assets/images/whiskey-products/firepit-chairs.jpg",
            text: "Firepit 6"
        }
    ]
}  

export const defaultWineChair = {
    id: uuid(),
    title: "Wine Adirondack Chair",
    description: "Gorgeous adirondack chairs handmade from wine barrel staves.  The curves of this chair fit your body like a glove!",
    price: 350.00,
    images: [
        {
        id: uuid(),
        imageId: 1,
        image: "../assets/images/wine-products/chair-1.jpg",
        text: "Chair 1"
        },
        {
        id: uuid(),
        imageId: 2, 
        image: "../assets/images/wine-products/chair-2.jpg",
        text: "Chair 2"
        },
        {
        id: uuid(),
        imageId: 3,
        image: "../assets/images/wine-products/chair-3.jpg",
        text: "Chair 3"
        },
        {
        id: uuid(),
        imageId: 4,
        image: "../assets/images/wine-products/chair-4.jpg",
        text: "Chair 4"
        }
        
    ]
}

export const defaultWineOttoman = {
id: uuid(),
title: "Wine Adirondack Ottoman",
description: "Gorgeous adirondack Ottoman handmade from wine barrel staves.  A great companion for your adirondack chair!",
price: 125.00,
images: [
    {
        id: uuid(),
        imageId: 1,
        image: "../assets/images/wine-products/ottoman-1.jpg",
        text: "Ottoman 1"
    },
    {
        id: uuid(),
        imageId: 2, 
        image: "../assets/images/wine-products/ottoman-2.jpg",
        text: "Ottoman 2"
    },
    {
        id: uuid(),
        imageId: 3,
        image: "../assets/images/wine-products/ottoman-3.jpg",
        text: "Ottoman 3"
    },
    {
        id: uuid(),
        imageId: 4,
        image: "../assets/images/wine-products/ottoman-chair.jpg",
        text: "Ottoman 4"
    }
    
]
}  

export const defaultWineStool = {
    id: uuid(),
    title: "Wine Stool",
    description: "Gorgeous stool handmade from wine barrel staves.  A great stool for bars, decks, kitchens, and just anywhere a stool is needed!",
    price: 225.00,
    images: [
        {
            id: uuid(),
            imageId: 1,
            image: "../assets/images/wine-products/stool-1.jpg",
            text: "Stool 1"
        },
        {
            id: uuid(),
            imageId: 2, 
            image: "../assets/images/wine-products/stool-2.jpg",
            text: "Stool 2"
        },
        {
            id: uuid(),
            imageId: 3,
            image: "../assets/images/wine-products/stool-3.jpg",
            text: "Stool 3"
        },
        {
            id: uuid(),
            imageId: 4,
            image: "../assets/images/wine-products/stool-6.jpg",
            text: "Stool 4"
        },
        {
            id: uuid(),
            imageId: 5,
            image: "../assets/images/wine-products/stools.jpg",
            text: "Stool 5"
        }
    ]
}  

export const defaultHoopGlobe = {
    id: uuid(),
    title: "Hoop Globe",
    description: "Unique globe made from barrel hoops.  These globes make great yard decorations.",
    price: 75.00,
    images: [
        {
            id: uuid(),
            imageId: 1,
            image: "../assets/images/decor-products/globe.jpg",
            text: "Globe 1"
        },
        {
            id: uuid(),
            imageId: 2, 
            image: "../assets/images/decor-products/globe-1.jpg",
            text: "Globe 2"
        },
        {
            id: uuid(),
            imageId: 3,
            image: "../assets/images/decor-products/globe-grass-1.jpg",
            text: "Globe 3"
        },
        {
            id: uuid(),
            imageId: 4,
            image: "../assets/images/decor-products/globe-grass-2.jpg",
            text: "Globe 4"
        },
        {
            id: uuid(),
            imageId: 5,
            image: "../assets/images/decor-products/globe-snow-1.jpg",
            text: "Globe 5"
        },
        {
            id: uuid(),
            imageId: 6,
            image: "../assets/images/decor-products/globe-snow-2.jpg",
            text: "Globe 6"
        }
    ]
}  

export const defaultHoopLight = {
    id: uuid(),
    title: "Hoop Hanging Light",
    description: "A hanging light fixture made from barrel hoops.  This goes great with an industrial themed decor.  Would also look great over a bar or recreation room.  The oversized edison bulb adds to the appeal.",
    price: 150.00,
    images: [
        {
            id: uuid(),
            imageId: 1,
            image: "../assets/images/decor-products/hoop-light-1.jpg",
            text: "Light 1"
        },
        {
            id: uuid(),
            imageId: 2, 
            image: "../assets/images/decor-products/hoop-light-2.jpg",
            text: "Light 2"
        },
        {
            id: uuid(),
            imageId: 3,
            image: "../assets/images/decor-products/hoop-light-3.jpg",
            text: "Light 3"
        },
        {
            id: uuid(),
            imageId: 4,
            image: "../assets/images/decor-products/hoop-light-4.jpg",
            text: "Light 4"
        },
        {
            id: uuid(),
            imageId: 5,
            image: "../assets/images/decor-products/hoop-light-5.jpg",
            text: "Light 5"
        },
        {
            id: uuid(),
            imageId: 6,
            image: "../assets/images/decor-products/hoop-light-6.jpg",
            text: "Light 6"
        },
        {
            id: uuid(),
            imageId: 7,
            image: "../assets/images/decor-products/hoop-light-7.jpg",
            text: "Light 7"
        }
    ]
}  

export const defaultHoopWineSign = {
    id: uuid(),
    title: "Hoop Wine Sign",
    description: "A wall sign made from a barrel hoop and laser cut steel.  Left raw this has a great industrial look.  Can also be powder coated black for a more refined appearance.",
    price: 75.00,
    images: [
        {
            id: uuid(),
            imageId: 1,
            image: "../assets/images/decor-products/wine-sign-1.jpg",
            text: "Wine Sign 1"
        },
        {
            id: uuid(),
            imageId: 2, 
            image: "../assets/images/decor-products/wine-sign-2.jpg",
            text: "Wine Sign 2"
        }
    ]
}  
