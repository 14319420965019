import React from 'react';
import { MDBContainer } from 'mdbreact';

import ProductGrid from './ProductGrid';


function Products( { title, products } ) {

    return (
        <React.Fragment>
            <MDBContainer className="bg-2 p-5 text-center" fluid >
                <h3>{title}</h3>
                <ProductGrid products={products} />
            </MDBContainer>
        </React.Fragment>
    );

}

export default Products;