import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBCarousel, MDBCarouselInner, MDBCarouselItem } from 'mdbreact';
import Layout from '../components/shared/Layout';

import './product.css';

function Product({ product, isToggleOpen, toggle}) {
    const { title, images, description, price } = product;

    const currencyFormat = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
    })

    return (
        <Layout isToggleOpen={isToggleOpen} toggle={toggle} minimalNavbar={true} title={title} >
        
            <MDBContainer className="bg-2 p-5 text-center" fluid >
                
                <MDBRow >
                    <MDBCol md="4" >
                        <MDBCarousel 
                            activeItem={1}
                            length={images.length}
                            showControls={true}
                            showIndicators={true}
                            className="z-depth-1"
                            style={{ margin: "0 0 30px 0"}}
                            slide
                        >
                            <MDBCarouselInner>
                            {images.map((image) => (
                                <MDBCarouselItem key={image.id} itemId={image.imageId} >
                                    <img key={image.id}
                                        className="d-block w-100 image-fluid"
                                        src={image.image}
                                        alt={image.text}
                                    />
                                </MDBCarouselItem>
                            ))}
                            </MDBCarouselInner>
                        </MDBCarousel>
                    </MDBCol>
                    <MDBCol md="8" className="pl-5 pr-5 text-left" >
                        <h2>{title}</h2>
                        <h3>{currencyFormat.format(price)}</h3>
                        <p>{description}</p>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </Layout>
    )
}

export default Product;