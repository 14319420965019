import React from 'react';
import { MDBCard, MDBCardBody, MDBCardImage, MDBCardTitle, MDBCardText, MDBCol, MDBLink } from 'mdbreact';



function ProductCard( { link, image, title, description } ) {

    return (
        <React.Fragment>
            <MDBCol md="3" >
                <MDBCard style={{ border: "5" }}>
                    <MDBLink to={link}>
                        <MDBCardImage className="img-fluid" src={image} waves />
                    </MDBLink>
                    <MDBCardBody>
                        <MDBCardTitle>{title}</MDBCardTitle>
                        <MDBCardText>{description}</MDBCardText>
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>
        </React.Fragment>
    );

}

export default ProductCard;