import React from 'react';
import { MDBContainer } from 'mdbreact';
import Layout from '../components/shared/Layout'


function InformationPage( { isToggleOpen, toggle } ) {

    return (
        <Layout isToggleOpen={isToggleOpen} toggle={toggle} minimalNavbar={true} title="Information" >
            <MDBContainer className="bg-3 p-5 text-center" fluid >
                <h3>Barrel Information</h3>
                <p className="p-5">Our products are made from locally sourced barrels no longer suitable for the purpose of making alcohol products.  The barrels are broken down and each stave is carefully sanded to a smooth finish, cut into component parts and hand assembled to make the finest products.</p>
            </MDBContainer>
        </Layout>
    );

}

export default InformationPage;