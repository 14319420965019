import React from "react";
import { MDBCol, MDBContainer, MDBRow } from 'mdbreact';
import SEO from '../shared/Seo'
import Navbar from '../shared/Navbar'
import Footer from './Footer';
import MainProductPage from '../../pages/welcome-msg';

import './Layout.css';

function Layout({ children, isToggleOpen, toggle, minimalNavbar = false, title}) {

  return (
    <MDBContainer fluid className="section" >
            <SEO title={title} />
            <MDBContainer className="main" >
                <MDBRow>
                    <MDBCol md="12" >
                        <header className="App-header" >
                            <Navbar isToggleOpen={isToggleOpen} toggle={toggle} minimalNavbar={minimalNavbar} />
                        </header>
                    </MDBCol>
                </MDBRow>
                {!minimalNavbar && (
                    <MDBRow >
                        <MDBContainer className="childrenWrapper" >
                            <div className="children" >
                                <MainProductPage />
                            </div>
                        </MDBContainer>
                    </MDBRow>
                )}
            </MDBContainer>
                <MDBRow >
                    <MDBContainer className="childrenWrapper" >
                        <div className="children" >
                            {children}
                        </div>
                    </MDBContainer>
                </MDBRow>
            <MDBRow >
                <MDBCol md="12" className="bg-5" >
                    <Footer />
                </MDBCol>
            </MDBRow>
    </MDBContainer>
    )
}

export default Layout;
