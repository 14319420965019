import React from 'react';
import { MDBRow } from 'mdbreact';

import ProductCard from './ProductCard';


function ProductGrid( { products } ) {

    return (
        <React.Fragment>
            <MDBRow center >
                {products.map( product => (
                    <ProductCard
                        key={product.id}
                        id={product.id}
                        title={product.title}
                        description={product.description}
                        image={product.image}
                        link={product.link}
                    />
                ))}
            </MDBRow>
        </React.Fragment>
    );

}

export default ProductGrid;